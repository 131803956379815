const configurations = {
    //baseUrl: 'http://3.136.92.227:8089/api/v3.0'  /* Deployment Server for Perscitus Developer*/,
    baseUrl: process.env.REACT_APP_BASE_URL,
    baseEnrollUrl: process.env.REACT_APP_BASE_ENROLL_URL,
    chat_Box_Id: process.env.REACT_APP_CHATBOX_ID,
    //agentURL : 'http://3.136.92.227:8088/api/v4', // Only for test server
    agentURL: process.env.REACT_APP_AGENT_URL,
    transactionURL: process.env.REACT_APP_TRANSACTION_URL,
    //transactionURL:"http://3.136.92.227:8085/api/v6", //for local and test server Transaction URL
    authenticationURL: process.env.REACT_APP_AUTHENTICATION_URL,
    tokenURL: process.env.REACT_APP_TOKEN_URL,
    leadGenrateURL: process.env.REACT_APP_LEAD_GENERATION_URL,
    enrollmentURL: process.env.REACT_APP_ENROLLMENT_URL,
    isProd: process.env.REACT_APP_ISPROD,
    token_password: process.env.REACT_APP_TOKEN_PASSWORD,
    token_username: process.env.REACT_APP_TOKEN_USERNAME,
    tiken_x_api_key: process.env.REACT_APP_TOKEN_X_API_KEY,
    lead_generation_x_api_key: process.env.REACT_APP_LEAD_GENERATION_X_API_KEY,
    clientId: '5448',
}
   
export default configurations;