import moment from 'moment';
import React, { Component }  from 'react';
import { withRouter } from 'react-router';

class Footer extends Component {
  
  render() {
    return (
      <div>    
        
                    
      <p style={{textAlign:'center'}}>{window.copyrightText()}</p>
                  
      </div>

    )
  }


}
export default withRouter(Footer);